.com_info .el-select .el-input__wrapper[data-v-556df3ac] {
  margin: 0 0 0 0;
}
.com_info[data-v-556df3ac] .el-input__wrapper {
  width: 200px;
}
.com_info .el-input[data-v-556df3ac] {
  margin: 0 0 0 0;
}
.bottom_tax .el-table .cell[data-v-556df3ac] {
  line-height: 23px !important;
}
.bottom_tax[data-v-556df3ac] {
  width: 100%;
  margin-top: 20px;
}