.common_dialog_box[data-v-556df3ac] {
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title[data-v-556df3ac] {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  line-height: 28px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 5px;
}
.common_dialog_box .common_title span[data-v-556df3ac] {
  color: var(--themeColor, #17a2b8);
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .item[data-v-556df3ac]:nth-child(2n+1) {
  margin-left: 0;
}
.new_dialog .el-button--primary[data-v-556df3ac] {
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}
.common_dialog_box .el-select[data-v-556df3ac] {
  margin: 0 0 0 0 !important;
  width: 100%;
}
.add_btn[data-v-556df3ac] {
  width: 91%;
  margin: 0 auto;
  text-align: right;
}
[data-v-556df3ac] .el-table tr th {
  background: var(--headerBasicColor) !important;
}