.othercss p[data-v-3714adff] {
  float: left;
  width: 200px;
  font-size: 15px;
  line-height: 24px;
}
.othercss .right_select[data-v-3714adff] {
  float: left;
  position: relative;
}
.othercss .right_select .add[data-v-3714adff] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -26px;
  top: 0;
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
  padding-top: 4px;
}
.othercss .right_select .add .svg-icon[data-v-3714adff] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.othercss .item_box[data-v-3714adff] {
  position: relative;
  margin-top: 0 !important;
  border-top: 0 !important;
  margin-bottom: 10px;
}
.othercss .item_box .close[data-v-3714adff] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -32px;
  top: 3px;
  font-size: 20px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.othercss .item_box .close .svg-icon[data-v-3714adff] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.addi[data-v-3714adff] {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: -26px;
  font-size: 22px;
  color: var(--themeColor, #17a2b8);
}